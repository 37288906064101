import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import GetAppIcon from '@material-ui/icons/GetApp';
import {
    Button,
    TextInput,
    // ReferenceInput,
    SelectInput,
    DateInput,
    useNotify,
    useRefresh,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
    source,
    requestStatuses,
} from './data';

import { callApi } from '@src/services/api/helpers';

const CollateralRequestForm = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const { resource, record } = props;
    const requestItems = record.request_items ? JSON.parse(record.request_items) : [];

    const [currentTab, setCurrentTab] = useState(0);
    // const [rts, setRts] = useState(record.crts);

    const handleTabChange = useCallback((event, tabId) => {
        setCurrentTab(tabId);
    }, [setCurrentTab]);

    const handleSendEmail = useCallback(async () => {
        try {
            const res = await callApi(`/collateral_requests/${record.id}/send_email`, {
                method: 'PUT',
                data: {
                    body: {},
                },
            });
            notify(`${res.data.message}`, 'info');
            refresh();
        } catch (e) {
            notify(`Error: ${e.message}`);
        }
    }, [record, notify, refresh]);

    return (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <DateInput
                        required
                        label="Date Requested"
                        source={source.requestedAt}
                    />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Date Completed"
                        source={source.completedAt}
                    />
                </Grid>
                <Grid item>
                    <SelectInput
                        label="Status"
                        source={source.status}
                        choices={requestStatuses}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="First Name"
                        source={source.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Last Name"
                        source={source.lastName}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Email"
                        source={source.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Phone Number"
                        source={source.phoneNumber}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency First Name"
                        source={source.agencyFirstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency Last Name"
                        source={source.agencyLastName}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency Email"
                        source={source.agencyEmail}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency Phone Number"
                        source={source.agencyPhoneNumber}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency Name"
                        source={source.agencyName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Agency Website"
                        source={source.agencyWebsite}
                    />
                </Grid>
            </Grid>

            {
                requestItems.length > 0 && (
                    <>
                        <Grid item>
                            <Tabs
                                value={currentTab}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="icon label tabs"
                                onChange={handleTabChange}
                            >
                                {
                                    requestItems.map((item) => {
                                        return (
                                            <Tab key={item.id} label={item.title} />
                                        );
                                    })
                                }
                            </Tabs>
                        </Grid>
                        <Grid container>
                            {
                                // eslint-disable-next-line complexity
                                requestItems.map((item, index) => {
                                    return (
                                        <Grid
                                            key={`tab-panel-${item.id}`}
                                            item
                                            xs={12}
                                            md={12}
                                            value={index}
                                            index={index}
                                            hidden={index !== currentTab}
                                        >
                                            <h3>{ `${item.title} Customization Options` }</h3>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </>
                )
            }
            {
                record.attachment && (
                    <Grid container>
                        <Grid item>
                            <Button
                                label="Download ZIP file"
                                href={record.download_url}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <GetAppIcon />
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            {
                record.status === 'approved' && (
                    <Grid container>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                label="Send Co-Branded Video Email"
                                onClick={handleSendEmail}
                            />
                        </Grid>
                    </Grid>
                )
            }
            {
                record.status === 'sent' && (
                    <Grid container>
                        <Grid item md={6}>
                            <DateInput
                                disabled
                                fullWidth
                                label="Sent to Printer At"
                                source={source.completedAt}
                            />
                        </Grid>
                    </Grid>
                )
            }
        </>
    );
};

CollateralRequestForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CollateralRequestForm;
